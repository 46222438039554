import axios from 'axios';
import GLOBAL from '../GLOBAL';
class MainModel {
    
    directEndpointFetch = (endpoint, data) => {
        let company_table_uuid = ''
        let token_line = ''
        if (localStorage.getItem('company_table_uuid') == '') {
            company_table_uuid = localStorage.getItem('company_table_uuid', '')
        }else{
            company_table_uuid = localStorage.getItem('company_table_uuid')
        }
        token_line = localStorage.getItem('token_line', '')
        return axios({
            url: endpoint,
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'company_table_uuid': company_table_uuid,
                'token_line': token_line,
            },
            data: data.body,
        })
        .then((response) => response.data)
        .catch((error) => ({ require: false, data: [], error }));
    }

    authEndpointFetch = async (endpoint, data) => {
        let company_table_uuid = ''
        let token_line = ''
        if (localStorage.getItem('company_table_uuid') == '') {
            company_table_uuid = localStorage.getItem('company_table_uuid', '')
        }else{
            company_table_uuid = localStorage.getItem('company_table_uuid')
        }
        token_line = localStorage.getItem('token_line', '')
        try {
            const response = await axios({
                url: endpoint,
                method: data.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'company_table_uuid': company_table_uuid,
                    ...GLOBAL.ACCESS_TOKEN,
                    'token_line': token_line,
                },
                data: data.body,
            });

            if (response.data.unauthorized) {
                // localStorage.clear()
                // window.location.reload()
            }

            return response.data;
        } catch (error) {
            return { require: false, data: [], error };
        }
    }
}

export class BaseFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${GLOBAL.BASE_SERVER.URL}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${GLOBAL.BASE_SERVER.URL}${data.url}`, data)
}
