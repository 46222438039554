import { BaseFetch } from "../main-model";
export default class ReportRepairModel extends BaseFetch {
  getReportRepairBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairBy",
      method: "POST",
      body: data,
    });
  getReportRepairById = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairById",
      method: "POST",
      body: data,
    });
  insertReportRepair = (data) =>
    this.authFetch({
      url: "report-repair/insertReportRepair",
      method: "POST",
      body: data,
    });
  updateReportRepairById = (data) =>
    this.authFetch({
      url: "report-repair/updateReportRepairById",
      method: "POST",
      body: data,
    });
  deleteReportRepairById = (data) =>
    this.authFetch({
      url: "report-repair/deleteReportRepairById",
      method: "POST",
      body: data,
    });
  getReportCreditBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportCreditBy",
      method: "POST",
      body: data,
    });
  getReportCountWorkJobBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportCountWorkJobBy",
      method: "POST",
      body: data,
    });
  getCalendarTechnicianBy = (data) =>
    this.authFetch({
      url: "report-repair/getCalendarTechnicianBy",
      method: "POST",
      body: data,
    });
  getReportRepairCreditBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairCreditBy",
      method: "POST",
      body: data,
    });
  getReportRepairCountJobBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairCountJobBy",
      method: "POST",
      body: data,
    });
  getReportRepairByTechnician = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairByTechnician",
      method: "POST",
      body: data,
    });
  getReportRepairDropDownBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairDropDownBy",
      method: "POST",
      body: data,
    });
  getReportRepairByClosingRepair = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairByClosingRepair",
      method: "POST",
      body: data,
    });
  getReportRepairByInProgress = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairByInProgress",
      method: "POST",
      body: data,
    });

  getReportRepairDateBy = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairDateBy",
      method: "POST",
      body: data,
    });
  getCreditJobBy = (data) =>
    this.authFetch({
      url: "report-repair/getCreditJobBy",
      method: "POST",
      body: data,
    });
    getReportRepairByJobNo = (data) =>
    this.authFetch({
      url: "report-repair/getReportRepairByJobNo",
      method: "POST",
      body: data,
    });
}
