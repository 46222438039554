import { BaseFetch } from "../main-model";
export default class ClaimModel extends BaseFetch {
  
    getClaimBy = (data) =>
    this.authFetch({
      url: "claim/getClaimBy",
      method: "POST",
      body: data,
    });
    getClaimById = (data) =>
    this.authFetch({
      url: "claim/getClaimById",
      method: "POST",
      body: data,
    });
    insertClaim = (data) =>
    this.authFetch({
      url: "claim/insertClaim",
      method: "POST",
      body: data,
    });
    insertMultiClaim = (data) =>
      this.authFetch({
        url: "claim/insertMultiClaim",
        method: "POST",
        body: data,
      });
    updateClaimById = (data) =>
    this.authFetch({
      url: "claim/updateClaimById",
      method: "POST",
      body: data,
    });
    deleteClaimById = (data) =>
    this.authFetch({
      url: "claim/deleteClaimById",
      method: "POST",
      body: data,
    });
}
