import { BaseFetch } from "../main-model";
export default class CheckCarModel extends BaseFetch {

    getCheckCarBy = (data) =>
    this.authFetch({
      url: "check-car/getCheckCarBy",
      method: "POST",
      body: data,
    });
    getCheckCarById = (data) =>
    this.authFetch({
      url: "check-car/getCheckCarById",
      method: "POST",
      body: data,
    });
    insertCheckCar = (data) =>
    this.authFetch({
      url: "check-car/insertCheckCar",
      method: "POST",
      body: data,
    });
    updateCheckCarId = (data) =>
    this.authFetch({
      url: "check-car/updateCheckCarId",
      method: "POST",
      body: data,
    });
    deleteCheckCarId = (data) =>
    this.authFetch({
      url: "check-car/deleteCheckCarId",
      method: "POST",
      body: data,
    });
    getCheckCarByRefId = (data) =>
      this.authFetch({
        url: "check-car/getCheckCarByRefId",
        method: "POST",
        body: data,
      });

}
