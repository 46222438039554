import { BaseFetch } from "../main-model";
export default class LogStatusRepairModel extends BaseFetch {
  getLogStatusRepairBy = (data) =>
    this.authFetch({
      url: "log-status-repair/getLogStatusRepairBy",
      method: "POST",
      body: data,
    });
  getLogStatusRepairById = (data) =>
    this.authFetch({
      url: "log-status-repair/getLogStatusRepairById",
      method: "POST",
      body: data,
    });
  insertLogStatusRepair = (data) =>
    this.authFetch({
      url: "log-status-repair/insertLogStatusRepair",
      method: "POST",
      body: data,
    });
  updateLogStatusRepairById = (data) =>
    this.authFetch({
      url: "log-status-repair/updateLogStatusRepairById",
      method: "POST",
      body: data,
    });
  deleteLogStatusRepairById = (data) =>
    this.authFetch({
      url: "log-status-repair/deleteLogStatusRepairById",
      method: "POST",
      body: data,
    });
}
