import { BaseFetch } from "../main-model";
export default class LogReportRepairModel extends BaseFetch {
  getLogReportRepairBy = (data) =>
    this.authFetch({
      url: "log-report-repair/getLogReportRepairBy",
      method: "POST",
      body: data,
    });
  getLogReportRepairById = (data) =>
    this.authFetch({
      url: "log-report-repair/getLogReportRepairById",
      method: "POST",
      body: data,
    });
  insertLogReportRepair = (data) =>
    this.authFetch({
      url: "log-report-repair/insertLogReportRepair",
      method: "POST",
      body: data,
    });
  updateLogReportRepairById = (data) =>
    this.authFetch({
      url: "log-report-repair/updateLogReportRepairById",
      method: "POST",
      body: data,
    });
  deleteLogReportRepairById = (data) =>
    this.authFetch({
      url: "log-report-repair/deleteLogReportRepairById",
      method: "POST",
      body: data,
    });
}
