import { BaseFetch } from "../main-model";
export default class CustomerModel extends BaseFetch {
  getCustomerBy = (data) =>
    this.authFetch({
      url: "customer/getCustomerBy",
      method: "POST",
      body: data,
    });
  getCustomerById = (data) =>
    this.authFetch({
      url: "customer/getCustomerById",
      method: "POST",
      body: data,
    });
  insertCustomer = (data) =>
    this.authFetch({
      url: "customer/insertCustomer",
      method: "POST",
      body: data,
    });
  updateCustomerById = (data) =>
    this.authFetch({
      url: "customer/updateCustomerById",
      method: "POST",
      body: data,
    });
  deleteCustomerById = (data) =>
    this.authFetch({
      url: "customer/deleteCustomerById",
      method: "POST",
      body: data,
    });
  insertCustomerAddressAndContact = (data) =>
    this.authFetch({
      url: "customer/insertCustomerAddressAndContact",
      method: "POST",
      body: data,
    });
  deleteCustomerAddressAndContact = (data) =>
    this.authFetch({
      url: "customer/deleteCustomerAddressAndContact",
      method: "POST",
      body: data,
    });
  getCustomerByAddress = (data) =>
    this.authFetch({
      url: "customer/getCustomerByAddress",
      method: "POST",
      body: data,
    });

  updateCustomerAddressAndContact = (data) =>
    this.authFetch({
      url: "customer/updateCustomerAddressAndContact",
      method: "POST",
      body: data,
    });
  updateCustomerByReportRepair = (data) =>
    this.authFetch({
      url: "customer/updateCustomerByReportRepair",
      method: "POST",
      body: data,
    });
  insertCustomerByReportRepair = (data) =>
    this.authFetch({
      url: "customer/insertCustomerByReportRepair",
      method: "POST",
      body: data,
    });
}
