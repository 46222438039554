import { BaseFetch } from "../main-model";
export default class CreditCurrentModel extends BaseFetch {

    getCreditCurrentBy = (data) =>
    this.authFetch({
      url: "credit-current/getCreditCurrentBy",
      method: "POST",
      body: data,
    });
    getCreditCurrentById = (data) =>
    this.authFetch({
      url: "credit-current/getCreditCurrentById",
      method: "POST",
      body: data,
    });
    insertCreditCurrent = (data) =>
    this.authFetch({
      url: "credit-current/insertCreditCurrent",
      method: "POST",
      body: data,
    });
    updateCreditCurrentById = (data) =>
    this.authFetch({
      url: "credit-current/updateCreditCurrentById",
      method: "POST",
      body: data,
    });
    deleteCreditCurrentById = (data) =>
    this.authFetch({
      url: "credit-current/deleteCreditCurrentById",
      method: "POST",
      body: data,
    });
    getMonitorCreditCurrentByCar = (data) =>
    this.authFetch({
      url: "credit-current/getMonitorCreditCurrentByCar",
      method: "POST",
      body: data,
    });
}
