import { BaseFetch } from "../main-model";
export default class EmployeeModel extends BaseFetch {

    getEmployeeBy = (data) =>
    this.authFetch({
      url: "employee/getEmployeeBy",
      method: "POST",
      body: data,
    });
    getEmployeeById = (data) =>
    this.authFetch({
      url: "employee/getEmployeeById",
      method: "POST",
      body: data,
    });
    insertEmployee = (data) =>
    this.authFetch({
      url: "employee/insertEmployee",
      method: "POST",
      body: data,
    });
    updateEmployeeById = (data) =>
    this.authFetch({
      url: "employee/updateEmployeeById",
      method: "POST",
      body: data,
    });
    deleteEmployeeById = (data) =>
    this.authFetch({
      url: "employee/deleteEmployeeById",
      method: "POST",
      body: data,
    });
    getEmployeeByEngineer = (data) =>
    this.authFetch({
      url: "employee/getEmployeeByEngineer",
      method: "POST",
      body: data,
    });
}
