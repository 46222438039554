import { BaseFetch } from "../main-model";
export default class StatusRepairModel extends BaseFetch {
    getDailyInChargeBy = (data) => {
    return this.directFetch({
      url: "daily-in-charge/getDailyInChargeBy",
      method: "POST",
      body: data,
    });
  };

  insertDailyInCharge = (data) => {
    return this.directFetch({
      url: "daily-in-charge/insertDailyInCharge",
      method: "POST",
      body: data,
    });
  };

  deleteDailyInChargeById = (data) => {
    return this.directFetch({
      url: "daily-in-charge/deleteDailyInChargeById",
      method: "POST",
      body: data,
    });
  };

 
}
