import { BaseFetch } from "../main-model";
export default class CarModel extends BaseFetch {

    getCarBy = (data) =>
    this.authFetch({
      url: "car/getCarBy",
      method: "POST",
      body: data,
    });
    getCarById = (data) =>
    this.authFetch({
      url: "car/getCarById",
      method: "POST",
      body: data,
    });
    insertCar = (data) =>
    this.authFetch({
      url: "car/insertCar",
      method: "POST",
      body: data,
    });
    updateCarById = (data) =>
    this.authFetch({
      url: "car/updateCarById",
      method: "POST",
      body: data,
    });
    deleteCarById = (data) =>
    this.authFetch({
      url: "car/deleteCarById",
      method: "POST",
      body: data,
    });
}
