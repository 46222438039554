import { BaseFetch } from "../main-model";
export default class ReportRepairModel extends BaseFetch {
    getTechnicianInChargeBy = (data) => {
    return this.directFetch({
      url: "techician-in-charge/getTechnicianInChargeBy",
      method: "POST",
      body: data,
    });
  };

  insertTechnicianInCharge = (data) => {
    return this.directFetch({
      url: "techician-in-charge/insertTechnicianInCharge",
      method: "POST",
      body: data,
    });
  };

  deleteTechnicianInChargeById = (data) => {
    return this.directFetch({
      url: "techician-in-charge/deleteTechnicianInChargeById",
      method: "POST",
      body: data,
    });
  };

 
}
