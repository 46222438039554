import { BaseFetch } from "../main-model";
export default class DistrictsModel extends BaseFetch {

  getDistrictsBy = (data) => {
      return this.directFetch({
        url: "districts/getDistrictsBy",
        method: "POST",
        body: data,
      })
    }

}
