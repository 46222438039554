import { BaseFetch } from "../main-model";
export default class CheckCaseModel extends BaseFetch {

    getCheckCaseBy = (data) =>
    this.authFetch({
      url: "check-case/getCheckCaseBy",
      method: "POST",
      body: data,
    });
    getCheckCaseById = (data) =>
    this.authFetch({
      url: "check-case/getCheckCaseById",
      method: "POST",
      body: data,
    });
    insertCheckCase = (data) =>
    this.authFetch({
      url: "check-case/insertCheckCase",
      method: "POST",
      body: data,
    });
    updateCheckCaseId = (data) =>
    this.authFetch({
      url: "check-case/updateCheckCaseId",
      method: "POST",
      body: data,
    });
    deleteCheckCaseId = (data) =>
    this.authFetch({
      url: "check-case/deleteCheckCaseId",
      method: "POST",
      body: data,
    });
}
