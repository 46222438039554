import { BaseFetch } from "../main-model";
export default class DepartmentModel extends BaseFetch {

    getDepartmentBy = (data) =>
    this.authFetch({
      url: "department/getDepartmentBy",
      method: "POST",
      body: data,
    });
    getDepartmentById = (data) =>
    this.authFetch({
      url: "department/getDepartmentById",
      method: "POST",
      body: data,
    });
    insertDepartment = (data) =>
    this.authFetch({
      url: "department/insertDepartment",
      method: "POST",
      body: data,
    });
    updateDepartmentById = (data) =>
    this.authFetch({
      url: "department/updateDepartmentById",
      method: "POST",
      body: data,
    });
    deleteDepartmentById = (data) =>
    this.authFetch({
      url: "department/deleteDepartmentById",
      method: "POST",
      body: data,
    });
}
