import { BaseFetch } from "../main-model";
export default class RunnoRepairModel extends BaseFetch {
    getRunnoRepairBy = (data) => {
    return this.authFetch({
      url: "runno-repair/getRunnoRepairBy",
      method: "POST",
      body: data,
    });
  };

  insertRunnoRepair = (data) => {
    return this.authFetch({
      url: "runno-repair/insertRunnoRepair",
      method: "POST",
      body: data,
    });
  };

}
