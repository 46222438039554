import { BaseFetch } from "../main-model";
export default class AttachmentModel extends BaseFetch {

    getAttachmentBy = (data) =>
    this.authFetch({
      url: "attachment/getAttachmentBy",
      method: "POST",
      body: data,
    });
    getAttachmentById = (data) =>
    this.authFetch({
      url: "attachment/getAttachmentById",
      method: "POST",
      body: data,
    });
    insertAttachment = (data) =>
    this.authFetch({
      url: "attachment/insertAttachment",
      method: "POST",
      body: data,
    });
    updateAttachmentById = (data) =>
    this.authFetch({
      url: "attachment/updateAttachmentById",
      method: "POST",
      body: data,
    });
    deleteAttachmentById = (data) =>
    this.authFetch({
      url: "attachment/deleteAttachmentById",
      method: "POST",
      body: data,
    });
}
