import { BaseFetch } from "../main-model";
export default class SiteModel extends BaseFetch {

    getSiteBy = (data) =>
    this.authFetch({
      url: "site/getSiteBy",
      method: "POST",
      body: data,
    });
    getSiteById = (data) =>
    this.authFetch({
      url: "site/getSiteById",
      method: "POST",
      body: data,
    });
    insertSite = (data) =>
    this.authFetch({
      url: "site/insertSite",
      method: "POST",
      body: data,
    });
    updateSiteById = (data) =>
    this.authFetch({
      url: "site/updateSiteById",
      method: "POST",
      body: data,
    });
    deleteSiteById = (data) =>
    this.authFetch({
      url: "site/deleteSiteById",
      method: "POST",
      body: data,
    });
    getSiteDateReportBy = (data) =>
    this.authFetch({
      url: "site/getSiteDateReportBy",
      method: "POST",
      body: data,
    });
}
