import { BaseFetch } from "../main-model";
export default class WithDrawModel extends BaseFetch {
  
    getWithDrawBy = (data) =>
    this.authFetch({
      url: "withdraw/getWithDrawBy",
      method: "POST",
      body: data,
    });
    getWithDrawApproveBy = (data) =>
    this.authFetch({
      url: "withdraw/getWithDrawApproveBy",
      method: "POST",
      body: data,
    });
    getWithDrawApproveHistoryBy = (data) =>
    this.authFetch({
      url: "withdraw/getWithDrawApproveHistoryBy",
      method: "POST",
      body: data,
    });
    getWithDrawApproveRevertBy = (data) =>
      this.authFetch({
        url: "withdraw/getWithDrawApproveRevertBy",
        method: "POST",
        body: data,
      });
    getWithDrawById = (data) =>
    this.authFetch({
      url: "withdraw/getWithDrawById",
      method: "POST",
      body: data,
    });
    insertWithDraw = (data) =>
    this.authFetch({
      url: "withdraw/insertWithDraw",
      method: "POST",
      body: data,
    });
    updateWithDrawById = (data) =>
    this.authFetch({
      url: "withdraw/updateWithDrawById",
      method: "POST",
      body: data,
    });
    updateWithDrawImgById = (data) =>
      this.authFetch({
        url: "withdraw/updateWithDrawImgById",
        method: "POST",
        body: data,
      });
    deleteWithDrawById = (data) =>
    this.authFetch({
      url: "withdraw/deleteWithDrawById",
      method: "POST",
      body: data,
    });
    getWithDrawPDF = (data) =>
    this.authFetch({
      url: "withdraw/getWithDrawPDF",
      method: "POST",
      body: data,
    });
    getWithDrawRevertBy = (data) =>
    this.authFetch({
      url: "withdraw/getWithDrawRevertBy",
      method: "POST",
      body: data,
    });
}
